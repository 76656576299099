/** This composable is logic for fetching and saving BOMs to hide the complexity of local storage vs. API */
const VALID_EXTENSIONS = ["xls", "xlsx", "csv", "tsv"];
const LOCAL_STORAGE_KEY = "ecia-bom-anonymous";

type UploadCompleteResponse = {
    truncated: boolean;
    res?: BOMSearch; // only if it's anonymous :-/
    Id?: string; // only if it's not anonymous
};

function getPartialBom(bom: BOMSearch): Partial<BOMSearch> {
    const { Name, Distributors, SortOrder, StockFilter, BatchSize, Parts: parts } = bom;

    const basicParts = parts.map(getBasicPart);

    return { Name, Distributors, SortOrder, StockFilter, BatchSize, Parts: basicParts };
}

function getBasicPart(part: BOMSearchPart): BOMSearchPart {
    const {
        Id,
        Key,
        PartId,
        PartNumber,
        PartNumberScrubbedNonMeaningful,
        ManufacturerId,
        RootManufacturerId,
        ManufacturerName,
        Quantity,
        SortNum,
        Exists,
        PartClickHash,
        InternalReferenceNumber,
    } = part;
    return {
        Id,
        Key,
        PartId,
        PartNumber,
        PartNumberScrubbedNonMeaningful,
        ManufacturerId,
        RootManufacturerId,
        ManufacturerName,
        Quantity,
        SortNum,
        Exists,
        PartClickHash,
        InternalReferenceNumber,
    };
}

export default function useBOM() {
    const { t } = useI18n();
    const api = useApi();
    const isAuthenticated = useStateIsAuthenticated();

    function fetchBOMs() {
        if (isAuthenticated.value) {
            return api<BOMSearch[]>("api/bom");
        } else {
            const bomString = localStorage.getItem(LOCAL_STORAGE_KEY);
            const bom = bomString ? (JSON.parse(bomString) as BOMSearch) : null;
            return bom ? [{ ...bom, HashId: "local" }] : [];
        }
    }

    async function fetchBOM(hash: string | string[]) {
        if (isAuthenticated.value) {
            const url = "api/bom/" + encodeURI(Array.isArray(hash) ? hash[0] : hash);
            return api<BOMSearch>(url, { method: "GET" });
        } else {
            const defaultBom = await api<BOMSearch>("api/bom/default");
            const bomString = localStorage.getItem(LOCAL_STORAGE_KEY);
            const localBom = bomString ? (JSON.parse(bomString) as BOMSearch) : {};
            return { ...defaultBom, ...localBom };
        }
    }

    function saveBOM(bom: BOMSearch) {
        const body = getPartialBom(bom);

        if (isAuthenticated.value) {
            if (bom.HashId) {
                return api<BOMSearch>("api/bom/" + encodeURI(bom.HashId), { method: "PUT", body });
            } else {
                return api<BOMSearch>("api/bom", { method: "POST", body });
            }
        } else {
            const bomString = JSON.stringify(body);
            localStorage.setItem(LOCAL_STORAGE_KEY, bomString);
            return { ...bom, HashId: "local" };
        }
    }

    async function uploadStart(file?: File) {
        if (!file) return;

        const ext = file.name.split(".").pop();
        if (!ext || !VALID_EXTENSIONS.includes(ext.toLowerCase())) {
            throw new Error(t("BOMTool.InvalidFormat"));
        }

        const fileSizeMb = file.size / 1_048_576;
        if (fileSizeMb > MAX_FILE_UPLOAD_MEGABYTES) {
            throw new Error(t("Global.MaxFileUploadSize", [MAX_FILE_UPLOAD_MEGABYTES]));
        }

        const body = new FormData();
        body.append("Files", file);
        return api<BOMImportFileDetail>("/api/bom/upload/start", { method: "POST", body });
    }

    function uploadComplete(bomImportFile: MaybeRefOrGetter<BOMImportFileDetail | null | undefined>) {
        const body = toValue(bomImportFile);
        if (!body) return;

        return api<UploadCompleteResponse>("/api/bom/upload/complete", { method: "POST", body });
    }

    return { fetchBOMs, fetchBOM, saveBOM, uploadStart, uploadComplete };
}
